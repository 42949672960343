import React from 'react';
import "./Footer.css";

export default function Footer() {
	return (
		<footer className="nibious-footer bg-dark text-wrap">
				<p style={{color: 'white', overflowWrap: "anywhere", fontSize: "1.25rem", marginRight: "1rem", paddingTop: ".3125rem", paddingBottom: "0", marginBottom: 0  }}>Developed in collaboration with <a target="_blank" rel="noopener noreferrer" href="https://cosci-llc.com">CoSci LLC</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.bfh.ch/en/research/research-areas/mountain-forests-natural-hazards-gis/">Bern University of Applied Sciences</a></p>
		</footer>
	);
}
