import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TreeInputOptions from "./input/TreeInputOptions";
import ChannelParameterInputOptions from "./input/ChannelParametersInputOptions";
import CatchmentInputOptions from "./input/CatchmentInputOptions";
import IdealVegetationInputOptions from "./input/IdealVegetationInputOptions";
import NumberBox from "../elements/NumberBox";
import CriticalShearStressOptions from './input/CriticalShearStressOptions';
import WoodTransportOptions from "./input/WoodTransportOptions";

export default function InputPanel(props) {
  const updateParameters = props.updateParameters;
  const parameters = props.parameters;

  function handleTextBoxOnChange(event) {
    if (
      event.target.name === "InnerTreeSpecies" ||
      event.target.name === "OuterTreeSpecies" ||
      event.target.name === "criticalShearStressModel" ||
      event.target.name === "calculationName"
    ) {
      updateParameters({
        ...parameters,
        [event.target.name]: event.target.value,
      });
    } else if (event.target.name === "idealVegetationUseTimeInterval") {
      updateParameters({
        ...parameters,
        [event.target.name]: event.target.checked,
        idealVegetationUseTreeSize: !event.target.checked,
      });
    } else if (event.target.name === "idealVegetationUseTreeSize") {
      updateParameters({
        ...parameters,
        [event.target.name]: event.target.checked,
        idealVegetationUseTimeInterval: !event.target.checked,
      });
    } else if (event.target.name === "criticalShearStressUserDefinedPaOuterCustom") {
      updateParameters({
        ...parameters,
        "criticalShearStressUserDefinedPaOuter":
          event.target.value === ""
            ? event.target.value
            : parseFloat(event.target.value)
      });
    } else if (event.target.name === "criticalShearStressUserDefinedPaInnerCustom") {
      updateParameters({
        ...parameters,
        "criticalShearStressUserDefinedPaInner":
          event.target.value === ""
            ? event.target.value
            : parseFloat(event.target.value)
      });
    } else {
      updateParameters({
        ...parameters,
        [event.target.name]:
          event.target.value === ""
            ? event.target.value
            : parseFloat(event.target.value)
      });
    }
  }

  function onFormSubmit(event) {
    if (
      parameters.channelWidth / 2 > parameters.InnerBankTreeDistance
    ) {
      props.setError("Check Inner Tree Distance Parameter");
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (
      parameters.channelWidth / 2 > parameters.OuterBankTreeDistance
    ) {
      props.setError("Check Outer Tree Distance Parameter");
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    event.preventDefault();
    props.submit();
  }

  return (
    <Form onSubmit={onFormSubmit}>
      <Container>
        <Row>
          <Col lg={6} sm={12} style={{ marginBottom: "1em" }}>
            <ChannelParameterInputOptions
              parameters={parameters}
              handleTextBoxOnChange={handleTextBoxOnChange}
            />
          </Col>

          <Col lg={6} sm={12} style={{ marginBottom: "1em" }}>
            <CatchmentInputOptions
              parameters={parameters}
              handleTextBoxOnChange={handleTextBoxOnChange}
              updateParameters={updateParameters}
            />
          </Col>
        </Row>

        <Row>
          <Col style={{ marginBottom: "1em"}}>
            <CriticalShearStressOptions
              parameters={parameters}
              handleTextBoxOnChange={handleTextBoxOnChange}
            />
          </Col>
        </Row>

        <Row>
          <Col style={{ marginBottom: "1em"}}>
            <WoodTransportOptions
              parameters={parameters}
              handleTextBoxOnChange={handleTextBoxOnChange}
            />
          </Col>
        </Row>

        <Row>
          <Col style={{ marginBottom: "1em" }}>
            <Card>
              <Card.Header>Vegetation</Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={6} sm={12} style={{ marginBottom: "1em" }}>
                    <TreeInputOptions
                      name="Inner Bank Tree"
                      parameters={parameters}
                      handleTextBoxOnChange={handleTextBoxOnChange}
                    />
                  </Col>
                  <Col lg={6} sm={12} style={{ marginBottom: "1em" }}>
                    <TreeInputOptions
                      name="Outer Bank Tree"
                      parameters={parameters}
                      handleTextBoxOnChange={handleTextBoxOnChange}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col style={{ marginBottom: "1em" }}>
            <Card>
              <Card.Header>Ideal Vegetation</Card.Header>
              <Card.Body>
                <Form.Group>
                  <Row>
                    <Col lg={6} sm={12}>
                      <Form.Check
                        inline
                        type="radio"
                        name="idealVegetationUseTimeInterval"
                        label="Time Interval"
                        id="idealVegetationUseTimeInterval"
                        checked={parameters.idealVegetationUseTimeInterval}
                        onChange={handleTextBoxOnChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Tree Size"
                        name="idealVegetationUseTreeSize"
                        id="idealVegetationUseTreeSize"
                        checked={parameters.idealVegetationUseTreeSize}
                        onChange={handleTextBoxOnChange}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                {parameters.idealVegetationUseTimeInterval && (
                  <Row>
                    <Col lg={6} sm={12} style={{ marginBottom: "1em" }}>
                      <Card>
                        <Card.Header>Time Interval Options</Card.Header>
                        <Card.Body>
                          <NumberBox
                            label="Years"
                            info="The amount of years to calculate the DBH and height for"
                            unit="yrs"
                            name="idealVegetationTimeInterval"
                            value={parameters.idealVegetationTimeInterval}
                            disabled={parameters.idealVegetationUseTreeSize}
                            onChange={handleTextBoxOnChange}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                )}
                  <Row>
                    <Col lg={6} sm={12} style={{ marginBottom: "1em" }}>
                      <IdealVegetationInputOptions
                        name="Inner Bank Tree"
                        parameters={parameters}
                        handleTextBoxOnChange={handleTextBoxOnChange}
                      />
                    </Col>
                    <Col lg={6} sm={12} style={{ marginBottom: "1em" }}>
                      <IdealVegetationInputOptions
                        name="Outer Bank Tree"
                        parameters={parameters}
                        handleTextBoxOnChange={handleTextBoxOnChange}
                      />
                    </Col>
                  </Row>

              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Card>
              <Card.Header>Calculation Information</Card.Header>
              <Card.Body>
                <Form.Label>
                Calculation Name
                </Form.Label>
                <Form.Control
                  value={parameters.calculationName}
                  name="calculationName"
                  onChange={handleTextBoxOnChange}
                  onFocus={(event) => { event.target.select(); }}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Button variant="primary" type="submit" style={{ width: "100%" }}>
              Calculate Erosion
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
}
/*
*    - Catchment area + Return period (catchmentArea, returnPeriod)
*    - Discharge + duration (discharge, duration)
*    - Catchment area + discharge

*/
