import React, { useEffect } from "react";
import getTransportDataset from "../getTransportDataset";
import Chart from "../../elements/Chart";

export default function TransportTab(props) {
  const [chartObjects, setChartObjects] = React.useState({
    xLabels: [],
    datasets: [],
  });
  const skipAmount = 1;

  useEffect(() => {
    if (props.datasets.length === 0) {
      return;
		}
		
    setChartObjects(getTransportDataset(props.datasets, skipAmount));
  }, [props.datasets]);

  if (
    chartObjects.xLabels !== undefined
  ) {
    return null;
  }

	return (
		<div>
			<h2>Wood Transport</h2>
			<Chart id="transportChart1" chartObjects={chartObjects[0]} />
			<hr />
		</div>
	);

}
