import React, { useEffect } from "react";
import getFlowHeightDataset from "../getFlowHeightDataset";
import getQSedDataset from "../getQsedDataset";
import Chart from "../../elements/FlowChart";
import Table from "react-bootstrap/Table";

function DisplayFlowHeightMetrics(maxDischarge, areaOfChannelAtMaxDischarge, inputs) {

  //====BEGIN AREA OF CHANNEL CALC====
  

  //Area of channel
  const area_of_channel = areaOfChannelAtMaxDischarge;

  //====END AREA OF CHANNEL CALC====

  //====BEGIN Steam power calc =====
  
  const density_of_water = 999
  const gravity = 9.81
  const stream_power = density_of_water * gravity * maxDischarge * inputs.channelSlope;
  //====END Steam power calc =====

  return (
    <Table striped bordered hover size="sm" id="flowHeightMetrics">
      <thead>
        <tr>
          <th></th>
          <th>Values</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Max Water Discharge (m<sup>3</sup>/s)</td>
          <td>{maxDischarge.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Mean Flow Velocity at Peak Flow (m/s)</td>
          <td>{(maxDischarge/area_of_channel).toFixed(2)}</td>
        </tr>
        <tr>
          <td>Stream Power at Peak Flow (W)</td>
          <td>{stream_power.toFixed(2)}</td>
        </tr>
      </tbody>
    </Table>
  );
}

function DisplaySedimentMetrics(metrics) {
  return (
    <Table striped bordered hover size="sm" id="sedimentMetrics">
      <thead>
        <tr>
          <th></th>
          <th>No Vegetation</th>
          <th>Vegetation</th>
          <th>Ideal Vegetation</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Total Sediment Volume (m<sup>3</sup>)</td>
          <td>{metrics.qSednoVeggie.totalSedimmentVolume.toFixed(2)}</td>
          <td>{metrics.qSedveggie.totalSedimmentVolume.toFixed(2)}</td>
          <td>{metrics.qSedidealVeggie.totalSedimmentVolume.toFixed(2)}</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default function FlowHeightTab(props) {
  const [chartObjects, setChartObjects] = React.useState({
    xLabels: [],
    datasets: [],
  });
  const skipAmount = 1;

  useEffect(() => {
    if (props.datasets.length === 0) {
      return;
		}
		const flowHeightObject = getFlowHeightDataset(props.datasets, skipAmount);     

    const qsedObject = getQSedDataset(props.datasets, skipAmount);

    flowHeightObject.datasets.splice(1, 0, qsedObject.datasets[0]);
    flowHeightObject.datasets.splice(3, 0, qsedObject.datasets[1]);
    flowHeightObject.datasets.splice(5, 0, qsedObject.datasets[2]);
    
    
    const combinedChartObjects = {
      yLabel : flowHeightObject.yLabel,
      datasets: flowHeightObject.datasets,
      metrics: {
        ...flowHeightObject.metrics,
        ...qsedObject.metrics
      },
      y1Label: qsedObject.y1Label
    }

    setChartObjects(combinedChartObjects);
  }, [props.datasets]);

  if (
    chartObjects.xLabels !== undefined &&
    chartObjects.xLabels.length === 0
  ) {
    return null;
  }

	return( 
		<div>
			<Chart id="flowHeightChart" chartObjects={chartObjects} />
			<hr/>
			<h3>Flow Height Metrics</h3>
			{DisplayFlowHeightMetrics(chartObjects.metrics.veggie.maxDischarge, chartObjects.metrics.veggie.areaOfChannelAtMaxDischarge, props.inputs)}
      <br/>
      <h3>Sediment Metrics</h3>
      {DisplaySedimentMetrics(chartObjects.metrics)}
	</div>
	);
}
