import React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import NumberBox from "../../elements/NumberBox";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function CriticalShearStressOptions(props) {
  const parameters = props.parameters;
  const handleTextBoxOnChange = props.handleTextBoxOnChange;

  function getTauCOuterLabel() {
    return (
      <p style={{ "margin": 0 }}>
        Outer User Defined &tau;<span style={{ fontSize: "0.6em", verticalAlign: "bottom" }} >c</span>
      </p>
    );
  } 
  function getTauCInnerLabel() {
    return (
      <p style={{ "margin": 0 }}>
        Inner User Defined &tau;<span style={{ fontSize: "0.6em", verticalAlign: "bottom" }} >c</span>
      </p>
    );
  } 
  
  
  function getSedimentLabel(extra) {
    return (
      <span>
        {extra} D<span className="supsub">
          <sup>bank</sup>
          <sub>50</sub>
          </span> 
      </span>
    );
  }

  return (
    <Card>
      <Card.Header>Critical Shear Stress</Card.Header>
      <Card.Body>
        <Container>
        <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label={getSedimentLabel("Inner")}
                controlId="d50SedimentInner"
                info="Mean sediment diameter for the bank"
                unit="mm"
                name="d50Inner"
                value={parameters.d50Inner}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label={getSedimentLabel("Outer")}
                controlId="d50SedimentOuter"
                info="Mean sediment diameter for the bank"
                unit="mm"
                name="d50Outer"
                value={parameters.d50Outer}
                onChange={handleTextBoxOnChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <Form.Group controlId={"criticalShearStressOptions"}>
                <Form.Label>Model</Form.Label>
                <Form.Control
                  name="criticalShearStressModel"
                  as="select"
                  value={parameters["criticalShearStressModel"]}
                  onChange={handleTextBoxOnChange}
                >
                  <option value="brownlie">Brownlie (1981)</option>
                  <option value="paphitis">Paphitis (2001)</option>
                  <option value="userDefined">User Defined</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
            { parameters["criticalShearStressModel"] === "userDefined" &&
              <Form.Group controlId={"userDefinedTauCOptionsInner"}>
              <Form.Label>{getTauCInnerLabel()}</Form.Label>
              <div style={{"position" : "relative"}}>
              <Form.Control
                name="criticalShearStressUserDefinedPaInner"
                as="select"
                value={parameters["criticalShearStressUserDefinedPaInner"]}
                onChange={handleTextBoxOnChange}
                style={{ "position": "relative"}}
              >
                <option value="0.4668128">2% Silt-clay (0.4668128 Pa)</option>
                <option value="1.056575">5% Silt-clay (1.056575 Pa)</option>
                <option value="2.1356">10% Silt-clay (2.1356 Pa)</option>
                <option value="4.5908">20% Silt-clay (4.5908 Pa)</option>
                <option value="7.3252">30% Silt-clay (7.3252 Pa)</option>
                <option value="10.1984">40% Silt-clay (10.1984 Pa)</option>
                <option value="13.07">50% Silt-clay (13.07 Pa)</option>
                <option value="15.7996">60% Silt-clay (15.7996 Pa)</option>
                <option value="18.2468">70% Silt-clay (18.2468 Pa)</option>
                <option value="20.2712">80% Silt-clay (20.2712 Pa)</option>
                <option value="21.7324">90% Silt-clay (21.7324 Pa)</option>
                <option value="22.49">100% Silt-clay (22.49 Pa)</option>
              </Form.Control>
              <Form.Control
                type="number"
                name="criticalShearStressUserDefinedPaInnerCustom"
                onChange={handleTextBoxOnChange}
                style={{ "position": "absolute", "top": "0px", "left": "0px", "right": "20px", "width": "auto"}}
                value={parameters["criticalShearStressUserDefinedPaInner"]}
              >
                </Form.Control>   
                </div>
            </Form.Group>
            }
            </Col>
            <Col md={6} sm={12}>
            { parameters["criticalShearStressModel"] === "userDefined" &&
              <Form.Group controlId={"userDefinedTauCOptionsOuter"}>
              <Form.Label>{getTauCOuterLabel()}</Form.Label>
              <div style={{"position" : "relative"}}>
              <Form.Control
                name="criticalShearStressUserDefinedPaOuter"
                as="select" 
                value={parameters["criticalShearStressUserDefinedPaOuter"]}
                onChange={handleTextBoxOnChange}
                style={{ "position": "relative"}}
              >
                <option value="0.4668128">2% Silt-clay (0.4668128 Pa)</option>
                <option value="1.056575">5% Silt-clay (1.056575 Pa)</option>
                <option value="2.1356">10% Silt-clay (2.1356 Pa)</option>
                <option value="4.5908">20% Silt-clay (4.5908 Pa)</option>
                <option value="7.3252">30% Silt-clay (7.3252 Pa)</option>
                <option value="10.1984">40% Silt-clay (10.1984 Pa)</option>
                <option value="13.07">50% Silt-clay (13.07 Pa)</option>
                <option value="15.7996">60% Silt-clay (15.7996 Pa)</option>
                <option value="18.2468">70% Silt-clay (18.2468 Pa)</option>
                <option value="20.2712">80% Silt-clay (20.2712 Pa)</option>
                <option value="21.7324">90% Silt-clay (21.7324 Pa)</option>
                <option value="22.49">100% Silt-clay (22.49 Pa)</option>
              </Form.Control>   
              <Form.Control
                type="number"
                name="criticalShearStressUserDefinedPaOuterCustom"
                onChange={handleTextBoxOnChange}
                style={{ "position": "absolute", "top": "0px", "left": "0px", "right": "20px", "width": "auto"}}
                value={parameters["criticalShearStressUserDefinedPaOuter"]}
              >
                </Form.Control>          
                </div>
            </Form.Group>
            }
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

