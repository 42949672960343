


function convertToArray(data) {
	return new Array(data.size()).fill(0).map((_, id) => data.get(id));
}

function createTree(Module, distance, dbh, height, species) {
	const position = new Module.Vec3(distance, 0, 0);
	const speciesObj = Module.SpeciesFactory.GetByString("AlnusIncana");
	const tree = new Module.Tree(position, speciesObj);
	tree.SetDBH(dbh);
	tree.SetHeight(height);
	return tree;
}

function createInputObject(Module, type, inputParameters) {
	// Basic Parameters
	const input = new Module.Input();

	if(inputParameters.catchmentArea !== "" && inputParameters.returnPeriod !== "") {
		input.SetReturnPeriod(inputParameters.returnPeriod);
		input.SetCatchmentArea(inputParameters.catchmentArea);
	} else if(inputParameters.discharge !== "" && inputParameters.duration !== "") {
		input.SetDischarge(inputParameters.discharge);
		input.SetDuration(inputParameters.duration);
	} else if(inputParameters.discharge !== "" && inputParameters.catchmentArea !== "") {
		input.SetDischarge(inputParameters.discharge);
		input.SetCatchmentArea(inputParameters.catchmentArea);
	} else if (inputParameters.catchmentArea !== "" && inputParameters.bankHeight === "") {
		 // Catch the bank height case
		input.SetCatchmentArea(inputParameters.catchmentArea);
	}
	else {
		console.log("Incorrect input parameters. Wrong pair");
		throw "Incorrect input parameters. Wrong pair";
	}

	input.SetManningCoefficient(inputParameters.manningCoefficient);
	input.SetChannelWidth(inputParameters.channelWidth);
	input.SetChannelSlope(inputParameters.channelSlope);
	input.SetBankAngleInner(inputParameters.innerBankSlope);
	input.SetBankAngleOuter(inputParameters.outerBankSlope);
	input.SetBendRadius(inputParameters.bendRadius);
	input.SetBankHeightInner(inputParameters.bankHeightInner);
	input.SetBankHeightOuter(inputParameters.bankHeightOuter);
	input.SetD50BankInner(inputParameters.d50Inner);
	input.SetD50BankOuter(inputParameters.d50Outer);
	input.SetD50Bed(inputParameters.d50SedimentBed);
	input.SetD50Stdev(0.0 * inputParameters.d50SedimentBed);
	input.SetDensityFluid(inputParameters.fluidDensity);

	if (inputParameters.erosionCoefficientChanged) {
		input.SetErosionCoefficient(inputParameters.erosionCoefficient)
	}


	const transportTree = createTree(Module,
		inputParameters.InnerBankTreeDistance,
		inputParameters.diameterOfLog,
		inputParameters.lengthOfLog,
		inputParameters.InnerBankTreeSpecies);

	input.SetTreeInnerTransport(transportTree);
	input.SetTreeOuterTransport(transportTree);

	switch(type) {
		case Module.ErosionType.NoVegetation:
		case Module.ErosionType.Vegetation:
			input.SetTreeInner(createTree(Module,
				inputParameters.InnerBankTreeDistance,
				inputParameters.InnerBankTreeDBH,
				inputParameters.InnerBankTreeHeight,
				inputParameters.InnerBankTreeSpecies));
	
			input.SetTreeOuter(createTree(Module,
				inputParameters.OuterBankTreeDistance,
				inputParameters.OuterBankTreeDBH,
				inputParameters.OuterBankTreeHeight,
				inputParameters.OuterBankTreeSpecies));

			break;
		case Module.ErosionType.IdealVegetation:
			const alpha_i = inputParameters.InnerBankTreeHeight / inputParameters.InnerBankTreeDBH;
			const dbh_i = inputParameters.InnerBankTreeDBH + (inputParameters.idealVegetationTimeInterval * 0.004);
			input.SetTreeInner(createTree(Module,
				inputParameters.InnerBankTreeDistance,
				dbh_i,
				alpha_i * dbh_i,
				inputParameters.InnerBankTreeSpecies));

			const alpha_o = inputParameters.OuterBankTreeHeight / inputParameters.OuterBankTreeDBH;
			const dbh_o = inputParameters.OuterBankTreeDBH + (inputParameters.idealVegetationTimeInterval * 0.004);
			input.SetTreeOuter(createTree(Module,
				inputParameters.OuterBankTreeDistance,
				dbh_o,
				alpha_o * dbh_o,
				inputParameters.OuterBankTreeSpecies));
			break;
		default:
			console.log("****WE GOT PROBLEMS*****");
	}
	
	if (inputParameters.criticalShearStressModel === "paphitis") {
		input.SetShieldsModel("Paphitis");
	} else if (inputParameters.criticalShearStressModel === "brownlie") {
		input.SetShieldsModel("Brownlie");
	} else if (inputParameters.criticalShearStressModel === "userDefined") {
		input.SetShieldsModel("userDefined");
	} 

	return input;
}


function getShieldsObject(Module, inputParameters) {
	if (inputParameters.criticalShearStressModel === "paphitis") {
		return { "inner": [
							new Module.Paphitis(0.188, 1.0, 0.0475),
							new Module.Paphitis(0.28, 1.2, 0.0750),
							new Module.Paphitis(0.075, 0.5, 0.0300)
						],
				"outer": [
					new Module.Paphitis(0.188, 1.0, 0.0475),
					new Module.Paphitis(0.28, 1.2, 0.0750),
					new Module.Paphitis(0.075, 0.5, 0.0300)
				]}
	} else if (inputParameters.criticalShearStressModel === "brownlie") {
		return { "inner": [new Module.Brownlie()], "outer": [new Module.Brownlie()] };

	} else if (inputParameters.criticalShearStressModel === "userDefined") {
		console.log(inputParameters.criticalShearStressUserDefinedPaInner);
		return { "inner": [new Module.UserDefined(inputParameters.criticalShearStressUserDefinedPaInner)], "outer": [new Module.UserDefined(inputParameters.criticalShearStressUserDefinedPaOuter)]};
	} else {
		throw new Error("Invalid Critical Shear Stress Model Used");
	}
}


function calculateErosion(Module, type, inputParameters, randomSeed) {
	// Create Input
	const input = createInputObject(Module, type, inputParameters);

	if (randomSeed !== null) {
		input.SetRandomSeed(randomSeed);
	}

	const newRandomSeed = input.GetRandomSeed();

	const returnObject = {
		"randomSeed": newRandomSeed
	};

	// Create Hydrograph
	const hydrograph = new Module.Hydrograph(input)

	// Create Shields
	const shields = getShieldsObject(Module, inputParameters);

	// Internally we don't differentiate between Ideal and Vegetation
	if (type === Module.ErosionType.IdealVegetation) {
		type = Module.ErosionType.Vegetation
	}

	console.log(input.to_string());

	let shieldsLocation = new Module.BankLocation();
	shieldsLocation.SetInner(shields.inner[0]);
	shieldsLocation.SetOuter(shields.outer[0]);

	// Build erosion class
	let erosion = Module.ErosionFactory.BuildWithShields(type, input, hydrograph, shieldsLocation);

	// Compute Erosion
	returnObject["erosionTotal"] = convertToArray(erosion.ComputeErosion(1));
	
	returnObject["erosionInner"] = convertToArray(erosion.GetErosionInner());
	returnObject["erosionOuter"] = convertToArray(erosion.GetErosionOuter());

	returnObject["flowHeight"] = convertToArray(erosion.GetChannelParameters().GetFlowHeight());

	const hydrographArray = convertToArray(erosion.GetHydrograph().GetHydrograph());
	returnObject["maxDischarge"] = Math.max(...hydrographArray)

	//Get area of channel at max discharge
	const maxIndex = hydrographArray.indexOf(returnObject["maxDischarge"]);

	// Need to add inner erosiuon and outer erosion at peak flow! peak flow inner/outer erosion
	const W = returnObject["erosionInner"][maxIndex] + returnObject["erosionOuter"][maxIndex] + inputParameters.channelWidth;

	// This should be the max flow height height...
	const H = returnObject["flowHeight"][maxIndex];

	//Area of channel
	// Get betaT
	const beta_i = inputParameters.innerBankSlope * Math.PI / 180.0;
	const beta_o = inputParameters.outerBankSlope * Math.PI / 180.0;
	const betaT  = 1.0 / Math.tan(beta_i) + 1.0 / Math.tan(beta_o);
	const area_of_channel = H / 2.0 * (2.0 * W + H * betaT);
	returnObject["areaOfChannelAtMaxDischarge"] = area_of_channel;

	// Find max Applied shear stress outer
	const appliedShearStressOuter = convertToArray(erosion.GetAppliedShearStress().GetTauAOuter());
	const maxAppliedShearStressOuter = Math.max(...appliedShearStressOuter);
	let indexOfAppliedShearStress = appliedShearStressOuter.indexOf(maxAppliedShearStressOuter)
	const criticalShearStressOuter = convertToArray(erosion.GetCriticalShearStress().GetTauCOuter())[indexOfAppliedShearStress];

	returnObject["maxAppliedShearStressOuter"] = maxAppliedShearStressOuter;
	returnObject["criticalShearStressOuter"] = criticalShearStressOuter;

	// Find max Applied shear stress outer
	const appliedShearStressInner = convertToArray(erosion.GetAppliedShearStress().GetTauAInner());
	const maxAppliedShearStressInner = Math.max(...appliedShearStressInner);
	indexOfAppliedShearStress = appliedShearStressInner.indexOf(maxAppliedShearStressInner)
	const criticalShearStressInner = convertToArray(erosion.GetCriticalShearStress().GetTauCInner())[indexOfAppliedShearStress];

	returnObject["maxAppliedShearStressInner"] = maxAppliedShearStressInner;
	returnObject["criticalShearStressInner"] = criticalShearStressInner;

	returnObject["maxRARPercentageInner"] = Math.max(...convertToArray(erosion.GetCriticalShearStress().GetRARInnerPercent()));
	returnObject["maxRARPercentageOuter"] = Math.max(...convertToArray(erosion.GetCriticalShearStress().GetRAROuterPercent()));


	const transportCapacityInner = convertToArray(erosion.GetWoodTransport().GetTransportCapacityInner());
	const transportCapacityOuter = convertToArray(erosion.GetWoodTransport().GetTransportCapacityOuter());

	returnObject["transportCapacity"] = [];

	for(var i = 0; i < transportCapacityInner.length; i++) {
		returnObject["transportCapacity"][i] = (transportCapacityInner[i] + transportCapacityOuter[i]) / 2.0;
	}
	
	returnObject["qsed"] = convertToArray(erosion.GetSedimentTransport().GetQsed());

	hydrograph.delete();
	erosion.delete();
	shields.inner[0].delete()
	shields.outer[0].delete()

	// Compute other erosions for shields
	for(let i = 1; i < shields.inner.length; i++) {

		// Create Hydrograph
		const hydrograph = new Module.Hydrograph(input)

		let shieldsLocation = new Module.BankLocation();
		shieldsLocation.SetInner(shields.inner[i]);
		shieldsLocation.SetOuter(shields.outer[i]);

		// Build erosion class
		let erosion = Module.ErosionFactory.BuildWithShields(type, input, hydrograph, shieldsLocation);

		const nIterations = inputParameters.criticalShearStressModel === "paphitis" ? 1 : 10;

		// Compute Erosion
		returnObject["erosionTotal" + i] = convertToArray(erosion.ComputeErosion(nIterations));
		returnObject["erosionInner" + i] = convertToArray(erosion.GetErosionInner());
		returnObject["erosionOuter" + i] = convertToArray(erosion.GetErosionOuter());

		hydrograph.delete();
		erosion.delete();
		shields.inner[i].delete()
		shields.outer[i].delete()

	}

	input.delete();

	return returnObject;
}

export default function ErosionCalculator(inputParameters) {
	return window.createBankForNET().then((Module) => {
		window.BankforNETModule = Module;
		try {
			// No Vegetation
			const noveggieData = calculateErosion(Module, Module.ErosionType.NoVegetation, inputParameters, 1);

			// Vegetation
			const veggieData = calculateErosion(Module, Module.ErosionType.Vegetation, inputParameters, noveggieData.randomSeed);		


			const idealVeggieData = calculateErosion(Module, Module.ErosionType.IdealVegetation, inputParameters, noveggieData.randomSeed);
			return {
				"noVeggie" : noveggieData,
				"veggie" : veggieData,
				"idealVeggie": idealVeggieData
			};
		} catch(err) {
			return {
				"error": err,
				"errorMsg": window.BankforNETModule.getExceptionMessage(err)
			}
		}		
	});
}