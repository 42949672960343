import React from "react";
import Card from "react-bootstrap/Card";
import NumberBox from "../../elements/NumberBox";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import './ChannelParametersInputOptions.css';

function getSedimentLabelBed() {
  return (
    <span>
      D<span className="supsub">
        <sup>sed</sup>
        <sub>50</sub>
        </span> 
    </span>
  );
}

function getManningCoefficientLabel() {
 // m^(1/3)/s
  return (
    <span>
      s/m<span style={{ fontSize: "0.7em", verticalAlign: "top" }}>1/3</span>
    </span>
  );
}


export default function TreeInputOptions(props) {
  const [showModal, setShowModal] = React.useState(false);
  const parameters = props.parameters;
  const handleTextBoxOnChange = props.handleTextBoxOnChange;

  return (
    <Card>
      <Card.Header>
        Channel Parameters{" "}
        <Button tabIndex="-1" variant="light" className="d-inline-flex align-items-center" onClick={() => setShowModal(true)}> 
          <FontAwesomeIcon icon={faInfoCircle} />
        </Button>
      </Card.Header>
      <Card.Body>
      <Modal centered show={showModal} size="lg" onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            Channel Parameters Help
          </Modal.Header>
          <Modal.Body>
            <div>
              <h2>Channel Parameter Diagram</h2>
              <img width="100%" src="/images/bankfornet_fig1.png"/>
              <hr/>
              <h2>Tree Parameter Diagram</h2>
              <img width="100%" src="/images/bankfornet_fig2.png"/>
            </div>
          </Modal.Body>

        </Modal>
        <Container>
          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="Channel Width"
                unit="m"
                step="0.1"
                name="channelWidth"
                value={parameters.channelWidth}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label="Channel Slope"
                step="0.001"
                unit="m/m"
                name="channelSlope"
                value={parameters.channelSlope}
                onChange={handleTextBoxOnChange}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="Inner Bank Slope"
                unit="deg"
                name="innerBankSlope"
                value={parameters.innerBankSlope}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label="Outer Bank Slope"
                unit="deg"
                name="outerBankSlope"
                value={parameters.outerBankSlope}
                onChange={handleTextBoxOnChange}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="Bend Radius"
                unit="m"
                step="0.1"
                name="bendRadius"
                value={parameters.bendRadius}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label="Manning Coefficient"
                controlId="manningCoefficient"
                unit={getManningCoefficientLabel()}
                step="0.01"
                name="manningCoefficient"
                value={parameters.manningCoefficient}
                onChange={handleTextBoxOnChange}
                required={false}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="Inner Bank Height"
                controlId="bankHeightInner"
                unit="m"
                step="0.01"
                info="Used only for calculating Root Area Ratio. If left blank, Catchment Area will be used to calculate bank height"
                name="bankHeightInner"
                value={parameters.bankHeightInner}
                onChange={handleTextBoxOnChange}
                required={false}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label="Outer Bank Height"
                controlId="bankHeightOuter"
                unit="m"
                step="0.01"
                info="Used only for calculating Root Area Ratio. If left blank, Catchment Area will be used to calculate bank height"
                name="bankHeightOuter"
                value={parameters.bankHeightOuter}
                onChange={handleTextBoxOnChange}
                required={false}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label={getSedimentLabelBed()}
                controlId="d50SedimentBed"
                info="Mean sediment diameter for transported sediments"
                unit="mm"
                name="d50SedimentBed"
                value={parameters.d50SedimentBed}
                onChange={handleTextBoxOnChange}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
