


function convertTranportToDataset(dataset, skipAmount, label, color) {
	const inner = {
		type: 'line',
		fill: false,
		data: dataset.transportCapacity.map(function(e, i) { return { x: i, y: e } }),
		borderColor: color[0],
		label: label,
		pointRadius: 0
	};
	
	return inner;
}

const yLabel = "Transport capacity"

export default function getTransportDataset(datasets, skipAmount) {
	//Datasets
	const noVeggie = datasets.noVeggie;
	const veggie = datasets.veggie;
	const idealVeggie = datasets.idealVeggie;
	
	const veggieDataset = convertTranportToDataset(veggie, skipAmount, "Vegetation", ['green', 'purple']);
	const idealVeggieDataset = convertTranportToDataset(idealVeggie, skipAmount, "Ideal Vegetation", ['orange', 'black']);

	return [
		{ 
			yLabel: yLabel,
			datasets: [
				veggieDataset,
				idealVeggieDataset
			]
		}	
	];
}
