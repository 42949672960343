import React from "react";
import Card from "react-bootstrap/Card";
import NumberBox from "../../elements/NumberBox";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function TreeInputOptions(props) {
  const parameters = props.parameters
  const handleTextBoxOnChange = props.handleTextBoxOnChange
  const cardName = props.name;
  const refName = cardName.replace(/\s+/g, '');
  const invalidState = parameters[refName + "DBH"] === "";


  function precision(a) {
    if (!isFinite(a)) return 0;
    var e = 1, p = 1;
    while (Math.round(a * e) / e !== a) { e *= 10; p++; }

    return p;
  }

  const DBHPrecision = invalidState ? 1 : precision(parameters[refName + "DBH"]);

  function getDBH() {

    if (invalidState) {
      return 0;
    }

    // Use the time interval equation
    // DBH = DBH_0 * (1 + years *0.004)
    if (parameters.idealVegetationUseTimeInterval) {
      parameters[refName + "IdealDBH"] = (parameters[refName + "DBH"] + (parameters.idealVegetationTimeInterval * 0.004)).toPrecision(DBHPrecision);
    }

    return parameters[refName + "IdealDBH"];
  }

  const dbh = getDBH()

  return (
    <Card>
      <Card.Header>{cardName}</Card.Header>
      <Card.Body>
        <Container>
          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="DBH"
                unit="m"
                step="0.01"
                name={refName + "IdealDBH"}
                value={ dbh }
                onChange={handleTextBoxOnChange}
                disabled={parameters.idealVegetationUseTimeInterval}
                invalid={invalidState}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
