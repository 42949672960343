import React from "react";
import Card from "react-bootstrap/Card";
import NumberBox from "../../elements/NumberBox";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function WoodTransportOptions(props) {
  const parameters = props.parameters;
  const handleTextBoxOnChange = props.handleTextBoxOnChange;

  return (
    <Card>
      <Card.Header>Wood Transport</Card.Header>
      <Card.Body>
        <Container>
        <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label={"Diameter of Log"}
                controlId="diameterOfLog"
                info="Diameter Of Log"
                unit="m"
                name="diameterOfLog"
                value={parameters.diameterOfLog}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label={"Length of Log"}
                controlId="lengthOfLog"
                info="Length of Log"
                unit="m"
                name="lengthOfLog"
                value={parameters.lengthOfLog}
                onChange={handleTextBoxOnChange}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

