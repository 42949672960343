import React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import NumberBox from "../../elements/NumberBox";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function TreeInputOptions(props) {
  const parameters = props.parameters;
  const handleTextBoxOnChange = props.handleTextBoxOnChange;
  const cardName = props.name;

  const refName = cardName.replace(/\s+/g, "");

  return (
    <Card>
      <Card.Header>{cardName}</Card.Header>
      <Card.Body>
        <Container>
          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="DBH"
                unit="m"
                step="0.01"
                name={refName + "DBH"}
                value={parameters[refName + "DBH"]}
                onChange={handleTextBoxOnChange}
              />
            </Col>   
            <Col md={6} sm={12}>
              <NumberBox
                label="Distance"
                info="Distance to Center of Channel"
                isInvalid={parameters.channelWidth/2 + 0.01 > parameters[refName + "Distance"]}
                feedback="Distance must be greater than half the channel width"
                required
                unit="m"
                step="0.01"
                name={refName + "Distance"}
                value={parameters[refName + "Distance"]}
                onChange={handleTextBoxOnChange}
              />
            </Col>         
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <Form.Group controlId={refName + ".treeSpecies"}>
                <Form.Label>Tree Species</Form.Label>
                <Form.Control
                  name={refName + "Species"}
                  as="select"
                  value={parameters[refName + "Species"]}
                  onChange={handleTextBoxOnChange}
                >
                  {/*<option value="Default">Default</option>
                  <option value="AilanthusAltissima">
                    Ailanthus altissima
                  </option>*/}
                  <option value="AlnusIncana">Alnus incana</option>
                  {/*<option value="BetulaPendula">Betula pendula</option>
                  <option value="CastaneaSativa">Castanea sativa</option>
                  <option value="FagusSylvatica">Fagus sylvatica</option>
                  <option value="JatrophaCurcasL">Jatropha curcasL</option>
                  <option value="PiceaAbies">Picea abies</option>
                  <option value="PinusRadiata">Pinus radiata</option>
                  <option value="PopulusNigra">Populus nigra</option>
                  <option value="QuercusPubescens">Quercus pubescens</option>
                  <option value="TrachycarpusFortunei">
                    Trachycarpus fortunei
                  </option> */}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
