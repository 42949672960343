

function convertDatasetToMetrics(dataset) {
	return {
		maxDischarge: dataset.maxDischarge,
		areaOfChannelAtMaxDischarge: dataset.areaOfChannelAtMaxDischarge
	}
}

function convertFlowHeightToDataset(dataset, skipAmount, label, color) {

	return {
		type: 'line',
		fill: false,
		data: dataset.flowHeight.map(function(e, i) { return { x: i, y: e } }),
		borderColor: color,
		label: label,
		pointRadius: 0,
		row: 'Flow Height',
		col: label,
		yAxisID: 'y'
	}
}


export default function getFlowHeightDataset(datasets, skipAmount) {
	
	//Datasets
	const noVeggie = datasets.noVeggie;
	const veggie = datasets.veggie;
	const idealVeggie = datasets.idealVeggie;

	const returnSets = [];
	
	returnSets.push(convertFlowHeightToDataset(noVeggie, skipAmount, "No Vegetation", 'red'));
	returnSets.push(convertFlowHeightToDataset(veggie, skipAmount, "Vegetation", 'blue'));
	returnSets.push(convertFlowHeightToDataset(idealVeggie, skipAmount, "Ideal Vegetation", 'green'));
	
	return { yLabel:"Flow Height [m]",
	 datasets: returnSets, metrics: {
		noVeggie: convertDatasetToMetrics(noVeggie),
		veggie: convertDatasetToMetrics(veggie),
		idealVeggie: convertDatasetToMetrics(idealVeggie),
	}};
}
