

function convertErosionToDataset(dataset, skipAmount, label, color, hidden) {

	const totals = []

	totals.push({
		type: 'line',
		fill: false,
		data: dataset.erosionTotal.map(function(e, i) { return { x: i, y: e } }),
		borderColor: color,
		label: "Total " + label,
		pointRadius: 0,
		hidden: hidden
	});

	if (dataset.erosionTotal1 !== undefined) {
		totals.push({
			type: 'line',
			fill: false,
			data: dataset.erosionTotal1.map(function(e, i) { return { x: i, y: e } }),
			borderColor: color,
			label: "_(2) Total " + label,
			pointRadius: 0,
			hidden: hidden
		});
	}

	if (dataset.erosionTotal2 !== undefined) {
		totals.push({
			type: 'line',
			fill: false,
			data: dataset.erosionTotal2.map(function(e, i) { return { x: i, y: e } }),
			borderColor: color,
			label: "_(3) Total " + label,
			pointRadius: 0,
			hidden: hidden
		});
	}

	const inners = [];
	inners.push({	
		type: 'line',
		fill: false,
		data: dataset.erosionInner.map(function(e, i) { return { x: i, y: e } }),
		borderColor: color,
		label: "Inner " + label,
		pointRadius: 0,
		borderDash: [5, 15],
		hidden: hidden
	});

	if (dataset.erosionInner1 !== undefined) {
		inners.push({
			type: 'line',
			fill: false,
			data: dataset.erosionInner1.map(function(e, i) { return { x: i, y: e } }),
			borderColor: color,
			label: "_(1) Inner " + label,
			pointRadius: 0,
			borderDash: [5, 15],
			hidden: hidden
		});
	}

	if (dataset.erosionInner2 !== undefined) {
		inners.push({
			type: 'line',
			fill: false,
			data: dataset.erosionInner2.map(function(e, i) { return { x: i, y: e } }),
			borderColor: color,
			label: "_(2) Inner " + label,
			pointRadius: 0,
			borderDash: [5, 15],
			hidden: hidden
		});
	}

	const outers = [];
	outers.push({
		type: 'line',
		fill: false,
		data: dataset.erosionOuter.map(function(e, i) { return { x: i, y: e } }),
		borderColor: color,
		label: "Outer " + label,
		pointRadius: 0,
		borderDash: [2, 5],
		hidden: hidden
	});

	if (dataset.erosionOuter1 !== undefined) {
		outers.push({
			type: 'line',
			fill: false,
			data: dataset.erosionOuter1.map(function(e, i) { return { x: i, y: e } }),
			borderColor: color,
			label: "_(1) Outer " + label,
			pointRadius: 0,
			borderDash: [2, 5],
			hidden: hidden
		});
	}

	if (dataset.erosionOuter2 !== undefined) {
		outers.push({
			type: 'line',
			fill: false,
			data: dataset.erosionOuter2.map(function(e, i) { return { x: i, y: e } }),
			borderColor: color,
			label: "_(2) Outer " + label,
			pointRadius: 0,
			borderDash: [2, 5],
			hidden: hidden
		});
	}

	return {
		datasets: [ ...totals,
			...inners,
			...outers],
		metrics: {
			maxShearStressInner: dataset.maxAppliedShearStressInner,
			maxShearStressOuter: dataset.maxAppliedShearStressOuter,
			maxRARPercentageInner: dataset.maxRARPercentageInner,
			maxRARPercentageOuter: dataset.maxRARPercentageOuter,
			criticalShearStressInner: dataset.criticalShearStressInner,
			criticalShearStressOuter: dataset.criticalShearStressOuter,
			totalErosionInner: Math.max(...dataset.erosionInner),
			totalErosionOuter: Math.max(...dataset.erosionOuter),
		}
	};
}

const yLabel = "Erosion [m]"

export default function getErosionDataset(datasets, skipAmount) {
	//Datasets
	const noVeggie = datasets.noVeggie;
	const veggie = datasets.veggie;
	const idealVeggie = datasets.idealVeggie;

	const returnData = []
	
	const noVeggieDataset = convertErosionToDataset(noVeggie, skipAmount, "No Vegetation", "#000000", true);
	const veggieDataset = convertErosionToDataset(veggie, skipAmount, "Vegetation", "#00AF00", false);
	const idealVeggieDataset = convertErosionToDataset(idealVeggie, skipAmount, "Ideal Vegetation", 'orange', true);
	
	returnData.push(...noVeggieDataset.datasets)
	returnData.push(...veggieDataset.datasets)
	returnData.push(...idealVeggieDataset.datasets)



	return [{ 
			yLabel: yLabel,
			datasets: returnData},

			{
				noVeggie: noVeggieDataset.metrics,
				veggie: veggieDataset.metrics,
				idealVeggie: idealVeggieDataset.metrics
			}
		
		]
	
}
