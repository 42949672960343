import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon }from '@fortawesome/react-fontawesome';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';

export default function NumberBox(props) {

	
	const label = props.label;
	const unit = props.unit;
	const disabled = props.disabled
	const onChangeCallback = props.onChange;
	const value = props.value;
	const name = props.name;
	const step = props.step || "any";
	const isInvalid = props.isInvalid;
	const feedback = props.feedback;
	const required = props.required === undefined ? true : props.required;
	const info = props.info;

	var controlId;
	if(props.controlId === undefined) {
		controlId = label.replace(/ +/g, "");
	} else {
		controlId = props.controlId;
	}
	
	function onFocusCallback(self, event) {
		/*
		 * When a box is clicked (onFocus event), it should select all the
		 * values inside it. This will make it easier for students. This was
		 * requested on issue #127
		 */
		self.target.select();
	}
		
	return(
		<Form.Group  controlId={`controls.${controlId}`}>
			<Form.Label>
				{label}

				{ info && <OverlayTrigger
					transition={false} //Currently disable transitions to prevent findDom Errors. See https://github.com/react-bootstrap/react-bootstrap/issues/5519
					delay={{ show: 100, hide: 500}} 
					overlay={<Tooltip id={label+"tooltop"}>{info}</Tooltip>}>
				{({ ref, ...triggerHandler }) => (
						<Button
							tabIndex="-1"
							variant="light"
							{...triggerHandler}
							className="d-inline-flex align-items-center"
						>
							<FontAwesomeIcon forwardedRef={ref} icon={faInfoCircle} />
						</Button>
					)}
				</OverlayTrigger> }

			</Form.Label>
			<InputGroup>
				<Form.Control min="0"
											isInvalid={isInvalid}
											name={name} 
											feedback={feedback}
											disabled={disabled} 
											onChange={onChangeCallback} 
											value={value} 
											type="number" 
											step={step} 
											required={required}
											onFocus={onFocusCallback}
											/>
											
					
				<InputGroup.Append>
					<InputGroup.Text>{unit}</InputGroup.Text>
				</InputGroup.Append>
				<Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
			</InputGroup>
		</Form.Group>
	);
}