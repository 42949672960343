const pluginName = 'chartjs-plugin-matrix-legend';
const padding = 15;
const line_length = 40;

/**
 * Checks if configuration contains all required fields
 * @param {Chart} chartInstance 
 */
 function checkPluginConfiguration(chartInstance) {
	const requiredFields = {  }

	if (!(pluginName in chartInstance.options.plugins)) {
			console.warn("Options are missing configuration for the 'axislegend' plugin.")
			chartInstance.options.plugins[pluginName] = {}
	}

	Object.keys(requiredFields).forEach((field) => {
			if (!(field in chartInstance.options.plugins[pluginName])) {
					console.warn("Plugin '" + pluginName + "' configuration is missing '" + field + "', using default value.")
					chartInstance.options.plugins[pluginName][field] = requiredFields[field]
			}
	})
}

function isNullOrUndef(value) {
	return value === null || typeof value === 'undefined';
}

function toFontString(font) {
	if (!font || isNullOrUndef(font.size) || isNullOrUndef(font.family)) {
		return null;
	}
	return (font.style ? font.style + ' ' : '')
		+ (font.weight ? font.weight + ' ' : '')
		+ font.size + 'px '
		+ font.family;
}

function isEventInBoundaries(event, chart) {

	const boundingRect = chart.chartArea;

	const x = event.clientX - boundingRect.left
	const y = event.clientY - boundingRect.top

	// NOTE: the Y axis is switched
	if (x < chart.chartArea.left || x > chart.chartArea.right ||
			y < chart.chartArea.top || y > chart.chartArea.bottom) {
			return false
	}

	return true
}

function onClick(chartInstance, event) {
	
	if (!isEventInBoundaries(event, chartInstance)) {
			return
	}
	console.log("MOUSE DOWN!");
	console.log(chartInstance);
	const boundingRect = chartInstance.chartArea;
	console.log(boundingRect);
	const location = { x: event.event.x , y: event.event.y  }
	
	// Find the clicked area
	chartInstance.config.data.datasets.forEach((dataset) => {
		
		// Was click inside the box for the dataset?

		// was it within the x coords?
		if (location.x < dataset.realPosition.x || location.x > dataset.realPosition.x + line_length) {
			return; //Nope
		}

		// was it within the y coords?
		if (location.y < dataset.realPosition.y - 10 || location.y > dataset.realPosition.y + 10) {
			return; //Nope
		}

		
		dataset.hidden = dataset.hidden === null ? dataset.visible : !dataset.hidden;
		chartInstance.update();
		return true
		
	});
}



const MatrixLegendPlugin = {
	id: pluginName,
	events: { "click": onClick },

	beforeInit: (chartInstance) => {
			checkPluginConfiguration(chartInstance)

			const legend = chartInstance.options.plugins.legend
			if (legend.display && legend.position === "top") {
					console.warn("When using the axis legend plugin, the legend must not be at the top, or the legend must be disabled. Moving legend to the bottom.")
					chartInstance.options.legend.position = "bottom"
			}
	},

	afterEvent: (chartInstance, args) => {
			
			if(args.event.type === "click") {
				console.log(args);
				onClick(chartInstance, args);
				args.changed = true;
				return;
			}
	},


	beforeLayout: (chartInstance) => {
			chartInstance.options.layout.padding.bottom = 100;
	},

	afterDraw: (chartInstance) => {

			// Divide data up into rows...
			const rows = {};
			chartInstance.config.data.datasets.forEach((dataset) => {
				if (dataset.row in rows) {
					rows[dataset.row].push(dataset);
				} else {
					rows[dataset.row] = [dataset];
				}
			});

			// Get cols
			const cols = {};
			chartInstance.config.data.datasets.forEach((dataset) => {
				if (!(dataset.col in cols)) {
					cols[dataset.col] = {"x": -1, "y": -1, "size": 0};
				} 
			});

			const base_y = chartInstance.scales.x.bottom + padding;
			
			const ctx = chartInstance.ctx;

			let total_length = 0;
			let col_name_length = 0;
			// Get length of each category
			for (let col in cols) {
				col_name_length += ctx.measureText(col).width + 10;
			}

			for (let row in rows) {
				// Print the label
				const textSize = ctx.measureText(row);
				let row_x_adj = textSize.width + col_name_length;
				if (row_x_adj > total_length) {
					total_length = row_x_adj;
				}
			}

			const base_x = chartInstance.width/2 - total_length/2;

			

			// Now print out row by row. We will be printing columns when we run
			// into them. This will make it easier to keep track of what is going 
			// on and it should be faster hopefully.
			let row_y_adj = 15;
			ctx.save();

			for (let row in rows) {
				let row_x_adj = 0;
				// Print the label
				ctx.font = toFontString(chartInstance.options.font);
				ctx.fillStyle = "#666";
				ctx.fillText(row, base_x, base_y + row_y_adj);
				const textSize = ctx.measureText(row);
				row_x_adj += textSize.width;

				// Move over and print each category
				for (let dataset_index in rows[row]) {
					const dataset = rows[row][dataset_index];
					if(cols[dataset.col].x === -1) { // Print a new column

						//Check for next available spot
						let x = base_x + row_x_adj + 10;
						for (let printed_col in cols) {
							//We have already printed a column -- find the farthest out
							if (cols[printed_col].x !== -1) {
								if (cols[printed_col].x >= x) {
									x = cols[printed_col].x + cols[printed_col].size.width + 10;
								}
							}
						}

						cols[dataset.col].x = x;
						cols[dataset.col].y = base_y;
						cols[dataset.col].size = ctx.measureText(dataset.col);

						ctx.font = toFontString(chartInstance.options.font);
						ctx.fillStyle = "#666";
						ctx.fillText(dataset.col, cols[dataset.col].x, cols[dataset.col].y)
					}
					
					const start_x = cols[dataset.col].x + cols[dataset.col].size.width/2 - line_length/2;
					const start_y = base_y + row_y_adj - cols[dataset.col].size.actualBoundingBoxAscent/2;

					if (chartInstance.options.plugins[pluginName].mouseClickLocation !== undefined) {
						if(chartInstance.options.plugins[pluginName].mouseClickLocation.x)

						chartInstance.options.plugins[pluginName].mouseClickLocation = undefined;
					}

					ctx.beginPath();
					// Now move underneath the column and draw the line!
					ctx.strokeStyle = dataset.borderColor;
					if (dataset.borderDash !== undefined) {
						ctx.setLineDash(dataset.borderDash);
					} else {
						ctx.setLineDash([]);
					}
					ctx.lineWidth = 3;
					ctx.moveTo(start_x, start_y);
					ctx.lineTo(start_x + line_length, start_y);
					ctx.stroke();
					if(dataset.hidden) {
						ctx.strokeStyle = '#666';
						ctx.lineWidth = 1;
						ctx.setLineDash([]);
						ctx.moveTo(start_x, start_y - 5);
						ctx.lineTo(start_x + line_length, start_y + 5);

						ctx.moveTo(start_x, start_y + 5);
						ctx.lineTo(start_x + line_length, start_y - 5);
						ctx.stroke();
					}

					ctx.closePath();

					// Add bounding box info to storage for click events later
					dataset.realPosition = { "x": start_x, "y": start_y }

				}

				row_y_adj += textSize.actualBoundingBoxAscent + 10;
			}

			ctx.strokeStyle = '';
			ctx.restore();
	}
}

export default MatrixLegendPlugin
