import React from 'react';
import * as serviceWorker from '../serviceWorker';
import Alert from 'react-bootstrap/Alert';

export default function UpdatePWAAlert() {

	const [showReload, setShowReload] = React.useState(false);
	const [waitingWorker, setWaitingWorker] = React.useState(null);

	const onSWUpdate = (registration) => {
		setShowReload(true);
		setWaitingWorker(registration.waiting);
	};

	React.useEffect(() => {
		serviceWorker.register({ onUpdate: onSWUpdate });
	}, []);

	const reloadPage = () => {
		if(waitingWorker !== null) {
			waitingWorker.postMessage({ type: 'SKIP_WAITING' });
		}
		
		setShowReload(false);
		window.location.reload(true);
	};

	return (
		<Alert show={showReload} variant='warning' onClick={reloadPage}>
    	Click here to update the page!
  	</Alert>
	);

}




