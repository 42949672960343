import React, { useEffect } from "react";
import getErosionDataset from "../getErosionDataset";
import Chart from "../../elements/Chart";
import Table from "react-bootstrap/Table";


function DisplayErosionMetrics(data, showRAR=true, type) {
  return (
    <Table striped bordered hover size="sm" id={"erosionMetrics_" + type}>
      <thead>
        <tr>
          <th></th>
          <th>Inner Bank</th>
          <th>Outer Bank</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Max Applied Shear Stress (Pa)</td>
          <td>{data.maxShearStressInner.toFixed(2)}</td>
          <td>{data.maxShearStressOuter.toFixed(2)}</td>
        </tr>
        { showRAR && 
        <tr>
          <td>Max RAR Percentage</td>
          <td>{data.maxRARPercentageInner.toFixed(2)}</td>
          <td>{data.maxRARPercentageOuter.toFixed(2)}</td>
        </tr>
        }
        <tr>
          <td>Critical Shear Stress (Pa)</td>
          <td>{data.criticalShearStressInner.toFixed(2)}</td>
          <td>{data.criticalShearStressOuter.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Total Erosion (m)</td>
          <td>{data.totalErosionInner.toFixed(2)}</td>
          <td>{data.totalErosionOuter.toFixed(2)}</td>
        </tr>
      </tbody>
    </Table>
  );
}

function HiddenExportTable(noVeggie, veggie, idealVeggie) {

  const data = [ noVeggie, veggie, idealVeggie];

  return (
    <Table hidden id="erosionMetricsAll">
      <thead>
        <tr>
          <th>Metrics</th>
          <th>No Vegetation</th>
          <th>Vegetation</th>
          <th>Ideal Vegetation</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Max Applied Shear Stress (Pa) (Inner)</td>
          {
            data.map((d, i) => {
              return <td key={"maxShearStressInner" + i.toString()}>{d.maxShearStressInner.toFixed(2)}</td>
            })
          }
        </tr>
        <tr>
          <td>Max Applied Shear Stress (Pa) (Outer)</td>
          {
            data.map((d, i) => {
              return <td key={"maxShearStressOuter" + i.toString()}>{d.maxShearStressOuter.toFixed(2)}</td>
            })
          }
        </tr>
        <tr>
          <td>Max RAR Percentage (Inner)</td>
          {
            data.map((d, i) => {
              return <td key={"maxRARPercentageInner" + i.toString()}>{d.maxRARPercentageInner.toFixed(2)}</td>
            })
          }
        </tr>
        <tr>
          <td>Max RAR Percentage (Outer)</td>
          {
            data.map((d, i) => {
              return <td key={"maxRARPercentageOuter" + i.toString()}>{d.maxRARPercentageOuter.toFixed(2)}</td>
            })
          }
        </tr>
        <tr>
          <td>Critical Shear Stress (Pa) (Inner)</td>
          {
            data.map((d, i) => {
              return <td key={"criticalShearStressInner" + i.toString()}>{d.criticalShearStressInner.toFixed(2)}</td>
            })
          }
        </tr>
        <tr>
          <td>Critical Shear Stress (Pa) (Outer)</td>
          {
            data.map((d, i) => {
              return <td key={"criticalShearStressOuter" + i.toString()}>{d.criticalShearStressOuter.toFixed(2)}</td>
            })
          }
        </tr>
        <tr>
          <td>Total Erosion (m) (Inner)</td>
          {
            data.map((d, i) => {
              return <td key={"totalErosionInner" + i.toString()}>{d.totalErosionInner.toFixed(2)}</td>
            })
          }
        </tr>
        <tr>
          <td>Total Erosion (m)</td>
          {
            data.map((d, i) => {
              return <td key={"totalErosion" + i.toString()}>{d.totalErosionOuter.toFixed(2)}</td>
            })
          }
        </tr>
      </tbody>
    </Table>
  )
}

export default function ErosionTab(props) {
  const [chartObjects, setChartObjects] = React.useState({
    xLabels: [],
    datasets: [],
  });
  const skipAmount = 1;

  useEffect(() => {
    if (props.datasets.length === 0) {
      return;
		}

    setChartObjects(getErosionDataset(props.datasets, skipAmount));
  }, [props.datasets]);

  if (
    chartObjects.xLabels !== undefined
  ) {
    return null;
  }

	return (
		<div>
			<h2>Erosion</h2>
			<Chart id="erosionChart" chartObjects={chartObjects[0]} />
			<hr />
			<h3>No Vegetation Metrics</h3>
			{DisplayErosionMetrics(chartObjects[1].noVeggie, false, "NoVegetation")}
			<h3>Vegetation Metrics</h3>
			{DisplayErosionMetrics(chartObjects[1].veggie, true, "Vegetation")}
			<h3>Ideal Vegetation Metrics</h3>
			{DisplayErosionMetrics(chartObjects[1].idealVeggie, true, "IdealVegetation")}
      {HiddenExportTable(chartObjects[1].noVeggie, chartObjects[1].veggie, chartObjects[1].idealVeggie)}
		</div>
	);

}